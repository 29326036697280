import flatpickr from 'flatpickr';
import rangePlugin from 'flatpickr/dist/plugins/rangePlugin';
import collect from 'collect.js';
import Breakpoints from './breakpoints';

window.updateQueryParams = (params) => {
    const queryParams = new URLSearchParams(window.location.search);
    params.forEach((paramObject) => {
        queryParams.set(paramObject.key, paramObject.value);
    });
    window.history.pushState(null, null, `?${queryParams.toString()}`);
};

document.addEventListener('alpine:init', () => {
    Alpine.data('tabs', (urlKey) => ({
        type: new URLSearchParams(window.location.search).get(urlKey),
        updateUrl(type) {
            window.updateQueryParams([{ key: urlKey, value: type }]);
        },
    }));

    Alpine.data('date_field', (liveWireValue = null) => ({
        flatpickrInstance: null,
        value: liveWireValue,
        initFlatPickr() {
            this.flatpickrInstance = flatpickr(this.$refs.date_input, {
                allowInput: true,
                altInput: true,
                altFormat: 'd/m/Y',
                format: 'd/m/Y',
                static: true,
            });
        },
        init() {
            this.initFlatPickr();
        },
    }));

    Alpine.data('dropdown_filter', (label, options = [], liveWireValue) => ({
        open: false,
        label,
        options,
        value: liveWireValue,
        close() {
            this.open = false;
        },
        toggle() {
            this.open = !this.open;
        },
        updateValue(newValue) {
            this.value = newValue;
        },
        get activeLabel() {
            if (!this.value) {
                return this.label;
            }
            const active = this.options.find((e) => e.value === this.value);
            return `${this.label}: ${active ? active.label : 'Unknown'}`;
        },
    }));

    Alpine.data('dropdown_datefilter', (label, liveWireStartDateValue, liveWireEndDateValue) => ({
        open: false,
        label,
        startDate: null,
        endDate: null,
        liveWireStartDateValue,
        liveWireEndDateValue,
        flatpickrInstance: null,
        initFlatPickr() {
            this.startDate = this.liveWireStartDateValue;
            this.endDate = this.liveWireEndDateValue;
            this.flatpickrInstance = flatpickr(this.$refs.start_date, {
                mode: 'range',
                dateFormat: 'd/m/Y',
                static: true,
                plugins: [new rangePlugin({ input: this.$refs.end_date })],
                onClose: (selectedDates) => {
                    this.setDate('startDate', selectedDates[0]);
                    this.setDate('endDate', selectedDates[1]);
                },
            });
            if (!this.startDate && !this.endDate) {
                this.flatpickrInstance.clear();
            }
        },
        init() {
            this.initFlatPickr();
        },
        setDate(property, date) {
            if (!date) {
                return null;
            }
            const fullDate = date.getDate().toString().padStart(2, '0');
            const fullMonth = (date.getMonth() + 1).toString().padStart(2, '0');
            const fullYear = date.getFullYear();
            this[property] = `${fullDate}/${fullMonth}/${fullYear}`;
        },
        close() {
            this.open = false;
        },
        toggle() {
            this.open = !this.open;
        },
        save() {
            this.liveWireStartDateValue = this.startDate;
            this.liveWireEndDateValue = this.endDate;
            this.close();
        },
        get activeLabel() {
            if (!this.value) {
                return this.label;
            }
            return `${this.label}: Unknown`;
        },
    }));

    Alpine.data('modal', () => ({
        open: false,
        openModal(event) {
            this.open = true;
            this.$dispatch('openModal', event.detail);
        },
        closeModal() {
            this.open = false;
            this.$dispatch('closeModal');
        },
    }));

    Alpine.data('declaration_form', (defaultSection, livewireSections) => ({
        defaultSection,
        activeSection: defaultSection,
        paginationSections: livewireSections,
        init() {
            this.updateSectionFromUrl();
        },
        get currentPageIndex() {
            return collect(this.paginationSections).search((item) => item.key === this.activeSection);
        },
        get currentSection() {
            return this.paginationSections[this.currentPageIndex];
        },
        get previousSection() {
            return collect(this.paginationSections).get(this.currentPageIndex - 1);
        },
        get nextSection() {
            return collect(this.paginationSections).get(this.currentPageIndex + 1);
        },
        updateQueryParam() {
            window.updateQueryParams([{ key: 'section', value: this.activeSection }]);
        },
        setActiveSection(newSection) {
            this.activeSection = newSection;
            this.updateQueryParam();
        },
        updateSectionFromUrl() {
            const urlSection = new URLSearchParams(window.location.search).get('section');
            this.activeSection = urlSection || this.defaultSection;
        },
        previousPage() {
            if (this.previousSection) {
                this.activeSection = this.previousSection.key;
                this.updateQueryParam();
            }
        },
        nextPage() {
            if (this.nextSection) {
                this.activeSection = this.nextSection.key;
                this.updateQueryParam();
            }
        },
    }));

    Alpine.data('change_requests', () => ({
        open: false,
        allowHidingAndShowing: false,
        init() {
            this.handleBreakpointChange();
        },
        toggle() {
            if (this.allowHidingAndShowing) {
                this.open = !this.open;
            }
        },
        handleBreakpointChange() {
            if (Breakpoints.atBreakpointOrHigher('2xl')) {
                this.allowHidingAndShowing = false;
                this.open = true;
            }
            if (Breakpoints.atBreakpointOrLower('xl')) {
                this.allowHidingAndShowing = true;
                this.open = false;
            }
        },
    }));

    Alpine.data('renewal_form', (renewalOpen) => ({
        open: renewalOpen,
        toggle() {
            this.open = !this.open;
        },
    }));
});
